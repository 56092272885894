import * as React from "react"
import Layout from '../components/Layout';

const AdmissionPage = () => {
  return (
    <Layout pageTitle="Admission">
      <h1>Hi</h1>
    </Layout>
  )
}

export default AdmissionPage
